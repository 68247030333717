<template>
  <Header/>
  <el-scrollbar class="layout-main-scrollbar">
  <!--内容-->
  <div class="page-my-home">
    <div class="banner">
      <img src="/static/images/user-banner.png" alt="">
    </div>
    <div class="main">
      <div class="gk-container-big">
        <!--  作者信息 -->
        <div class="my-info">
          <div class="left">
            <el-image :src="account.avatar" lazy>
              <template #error>
                <el-image src="/static/upload/tx2.png" lazy></el-image>
              </template>
            </el-image>
            <div class="info">
              <div class="name">{{ account.nickname }}</div>
              <div class="intro">{{ account.residecity }} ｜ {{ account.profession }}</div>
              <div class="static">
                <div class="item" v-for="(m,index) in authorStatistics" :key="index"><span>{{ m.name }}</span><i>{{ m.num }}</i></div>
              </div>
            </div>
          </div>
          <a href="/publish">
            <div class="btn-upload">+ 上传作品</div>
          </a>
        </div>
        <!----->
        <div class="gk-clear">
          <div class="my-home-side-bar">
            <div class="side-bar-btns">
              <router-link v-for="(m,index) in user_nav" :key="index" :to="m.url" class="item" :class="m.class" active-class="active">
                <div>
                  <img :src="m.icon" alt=""><span>{{m.name}}</span>
                </div>
              </router-link>
            </div>
            <!--最近访客-->
            <div class="module-visitor">
              <div class="tit">最近访客</div>
              <ul>
                <li v-for="(m,index) in userVisitor" :key="index">
                  <el-image :src="m.visitor_user_avatar" lazy>
                    <template #error>
                      <el-image src="/static/upload/tx2.png" lazy></el-image>
                    </template>
                  </el-image>
                  <span>{{ m.visitor_nickname }}</span>
                  <i>{{ m.show_time }}</i>
                </li>
              </ul>
            </div>
          </div>

          <router-view />

        </div>
      </div>
    </div>
  </div>
  <Footer/>
  </el-scrollbar>
</template>

<script>
//引入组件
import Header from "@/layout/components/header";
import Footer from "@/layout/components/footer";
import user from "@/api/user";
import {mapState} from "vuex";

export default {
  name: 'user',
  components: {Header, Footer},
  computed: {
    ...mapState(['account'])
  },
  data() {
    return {
      user_nav:[
        {name:'我的作品',url:'/user/home',class:'',icon:'/static/iconImg/icon-work.png'},
        {name:'收藏夹',url:'/user/folder',class:'threeWord',icon:'/static/iconImg/icon-folder.png'},
        {name:'我的关注',url:'/user/focus',class:'',icon:'/static/iconImg/icon-focus.png'},
        {name:'个人资料',url:'/user/account_info',class:'',icon:'/static/iconImg/icon-accout-set.png'},
        //{name:'活动中心',url:'',checked:false,class:'',icon:'/static/iconImg/icon-activity.png'},
      ],
      authorStatistics:[],
      userVisitor:[]
    }
  },
  created() {
    this.getAuthorStatistics();
  },
  mounted() {
  },
  methods: {
    //获取用户访客记录
    getUserVisitor(){
      user.getUserVisitor({
        uid: this.uid,
        pagesize:4
      }).then(res => {
        if (res) {
          this.userVisitor = res.data;
        } else {
          console.log(res);
        }
      })
    },
    //调取 用户 作品，关注，收藏 统计
    getAuthorStatistics() {
      this.loading = true
      user.getAuthorStatistics({
        uid: 0
      }).then(res => {
        if (res) {
          this.authorStatistics = res.data;
        } else {
          console.log(res);
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.page-my-home .main .my-home-side-bar .side-bar-btns .item:hover{
  background: #F7F7F7;
}
.layout-container {
  height: 100%;
  width: 100%;
}
.layout-main-scrollbar {
  width: 100%;
  position: relative;
  overflow: hidden;
  height: calc(100vh - 100px);
}
.content-wrapper {
  flex-direction: column;
  width: 100%;
  height: 100%;
}
</style>
